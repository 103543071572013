/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import WomanAndTablet from '../images/people/woman-and-tablet.png'
import WomanAndTabletMobile from '../images/people/woman-and-tablet-mobile.png'

import borg from '../images/brand-logos/borg-black-tab.png'
import ctv from '../images/brand-logos/ctv-black-tab.png'
import hcp from '../images/brand-logos/hcp-black-tab.png'
import hsi from '../images/brand-logos/hsi-black-tab.png'
import move from '../images/brand-logos/move-black-tab.png'
import rev from '../images/brand-logos/reviews-black-tab.png'
import si from '../images/brand-logos/sat-int-black-tab.png'
import sw from '../images/brand-logos/safewise-black-tab.png'
import tph from '../images/brand-logos/tph-black-tab.png'
import wo from '../images/brand-logos/wo-black-tab.png'

import {
  Billboard,
  Column,
  Columns,
  LinkButton,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'
import NarrowBanner from '../components/NarrowBanner'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Explore Our Diverse Portfolio of Brands | Clearlink',
          description:
            'Through our portfolio of brands, we empower people to find, buy, and use products and services that improve their everyday lives.',
          canonical: 'https://clearlink.com/our-properties',
          robots: 'follow,index',
        },
        path: '/our-properties',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Clearlink | Our Properties',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          className="mobile-image-full no-padding-bottom-mobile"
          variant="full"
          alignImageToBottom
          mobileAlignImageToBottom
          image={
            <img
              src={WomanAndTablet}
              alt="basic alt billboard"
              className="image-contain"
            />
          }
          mobileImage={
            <img src={WomanAndTabletMobile} alt="basic alt billboard" />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h1">
                Clearlink Properties
                <span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                Through our portfolio of brands, we empower people to find, buy,
                and use products and services that improve their everyday lives.
              </Typography>
            </div>
          }
        />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                We build & acquire brands that guide people through purchase
                decisions<span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography variant="body" style={{ marginBottom: '32px' }}>
                We have a diverse portfolio of brands, and we acquire existing
                brands that fit our mission to empower people to make better
                decisions. Our brands focus on helping people navigate the
                complexities of moving, choosing home services, improving
                financial well-being, and running successful small businesses.
                We help make life less stressful for people every day.
              </Typography>
            </>
          }
        >
          <Columns
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '32px' }}
          >
            <Column className="no-content-padding-mobile">
              <img src={hsi} alt="highspeedinternet.com black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Internet services, simplified
              </Typography>
              <Typography variant="body">
                Helping more than 2 million visitors per month,
                HighSpeedInternet makes internet-related decisions fast and
                easy. With thousands of internet providers in our database, we
                find the perfect internet match based on your area, budget, and
                needs. The easy-to-use internet comparison tool helps 6.5
                million people a year find the right internet service provider.
              </Typography>
              <LinkButton
                to="https://www.highspeedinternet.com/"
                color="primary"
              >
                Visit HighSpeedInternet.com
              </LinkButton>
            </Column>
            <Column className="no-content-padding-mobile">
              <img src={rev} alt="reviews.org black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Helpful reviews for the connected home
              </Typography>
              <Typography variant="body">
                Reviews.org has reviewed 400+ products and services that connect
                your home to your life, so you can answer not only if something
                is “good” but if it’s good for you. The Reviews.org team has 77+
                years of industry experience, and they help over 2.3 million
                people a month navigate complex decisions to improve their
                everyday lives.
              </Typography>
              <LinkButton to="https://www.reviews.org/" color="primary">
                Visit Reviews.org
              </LinkButton>
            </Column>
          </Columns>
          <Columns
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '32px' }}
          >
            <Column className="no-content-padding-mobile">
              <img src={ctv} alt="cabletv.com black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Your TV and internet experts
              </Typography>
              <Typography variant="body">
                Every month, CableTV helps over 2.2 million people find, buy,
                and use TV-related services. The CableTV team watches 5,000+
                hours of TV a year to test products and services, so they can
                make the best possible recommendations.
              </Typography>
              <LinkButton to="https://www.cabletv.com/" color="primary">
                Visit CableTV.com
              </LinkButton>
            </Column>
            <Column className="no-content-padding-mobile">
              <img src={wo} alt="whistleout.com black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Love your phone and internet plans
              </Typography>
              <Typography variant="body">
                With 3.4 million visitors every month, WhistleOut operates in
                Australia, the United States, Mexico, and Canada. The team has a
                combined 113+ years of industry experience and expertly compares
                thousands of cell phone and internet plans and combinations.
                Using WhistleOut is the fast, free, and easy way to get the best
                deal.
              </Typography>
              <LinkButton to="https://www.whistleout.com/" color="primary">
                Visit WhistleOut US
              </LinkButton>
            </Column>
          </Columns>
          <Columns
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '32px' }}
          >
            <Column className="no-content-padding-mobile">
              <img src={si} alt="satelliteinternet.com black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Internet for everyone
              </Typography>
              <Typography variant="body">
                Satellite Internet is on a mission to bring the best possible
                internet experience to more people—whether they live in a small
                town, a cabin, on a farm, or on the road. The
                SatelliteInternet.com team has over 40 years of industry
                experience, and the team crunches data, tests products, and
                compares satellite internet plans to help people get the best
                service in their area for their needs.
              </Typography>
              <LinkButton
                to="https://www.satelliteinternet.com/"
                color="primary"
              >
                Visit SatelliteInternet.com
              </LinkButton>
            </Column>
            <Column className="no-content-padding-mobile">
              <img src={move} alt="move.org black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Take the stress out of moving
              </Typography>
              <Typography variant="body">
                Every month, Move.org helps over half a million people through
                their moving process. In one place, you can find, compare, and
                hire professional movers. The Move.org team has reviewed over
                150+ moving products and services to ensure they make the best
                possible recommendations. Move.org helps ease the most painful
                parts of moving. In fact, the brand’s main goal is to make
                moving a joy.
              </Typography>
              <LinkButton to="https://www.move.org/" color="primary">
                Visit Move.org
              </LinkButton>
            </Column>
          </Columns>
          <Columns
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            style={{ marginBottom: '32px' }}
          >
            <Column className="no-content-padding-mobile">
              <img src={sw} alt="safewise.com black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Buy smart, live safe
              </Typography>
              <Typography variant="body">
                Every month, SafeWise helps 1.5 million people navigate safety
                and security topics to help protect their loved ones. With over
                65 years of combined industry experience, the SafeWise team
                conducts rigorous hands-on testing of security products and
                services to help people make informed decisions. SafeWise’s
                proprietary data reports have helped more than 327 million
                readers and viewers understand safety and security in their
                homes and communities.
              </Typography>
              <LinkButton to="https://www.safewise.com/" color="primary">
                Visit SafeWise.com
              </LinkButton>
            </Column>
            <Column className="no-content-padding-mobile">
              <img src={tph} alt="thepennyhoarder.com black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Personal finance for everyone
              </Typography>
              <Typography variant="body">
                The Penny Hoarder is one of the largest personal finance
                websites in North America, receiving 10+ million visitors each
                month who are looking to make and save money. Our mission is to
                empower people to make smart choices with their money by sharing
                actionable resources to teach people how to earn, save, and
                manage their money.
              </Typography>
              <LinkButton to="https://www.thepennyhoarder.com/" color="primary">
                Visit ThePennyHoarder.com
              </LinkButton>
            </Column>
          </Columns>
          <Columns
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Column className="no-content-padding-mobile">
              <img src={borg} alt="business.org black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Skyrocket your business
              </Typography>
              <Typography variant="body">
                Business.org offers small-business owners the tools and
                information to keep their doors open and better their everyday
                operations. We whittle down the options and do the bulk of the
                financial and product research on their behalf so they can make
                smart choices efficiently, saving them time and effort in their
                already busy lives. And by seeking out partners, diverse
                channels, and concrete data, we strive to further improve their
                experience.
              </Typography>
              <LinkButton to="https://www.business.org/" color="primary">
                Visit Business.org
              </LinkButton>
            </Column>
            <Column className="no-content-padding-mobile">
              <img src={hcp} alt="move.org black tab" />
              <Typography variant="h4" style={{ paddingTop: '24px' }}>
                Smart solutions for your medicare needs
              </Typography>
              <Typography variant="body">
                Healthcareplans.com provides seniors with detailed medicare plan
                descriptions and expert advice – empowering them to find the
                best option for their healthcare needs. Customers can easily
                view providers, compare plans, and enroll completely online.
                With a team of licensed agents on hand to answer questions and
                sort though what can be a confusing process, Healthcareplans.com
                is committed to helping seniors feel confident and protected.
              </Typography>
              <LinkButton to="https://www.healthcareplans.com/" color="primary">
                Visit HealthcarePlans.com
              </LinkButton>
            </Column>
          </Columns>
        </VariableContent>

        <NarrowBanner
          backgroundColor="#1990FF"
          heading={<Typography variant="h2">Business inquiry?</Typography>}
          button={
            <LinkButton
              to="/contact"
              color="dark"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Contact Us
            </LinkButton>
          }
        />
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
